import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const Signature = ({ base64Image, setBase64Image }) => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [hasSignature, setHasSignature] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '', show: false });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const resizeCanvas = () => {
      const container = canvas.parentElement;
      const { width, height } = container.getBoundingClientRect();

      const scale = window.devicePixelRatio || 1;
      canvas.width = width * scale;
      canvas.height = height * scale;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      const context = canvas.getContext('2d');
      context.scale(scale, scale);
      context.lineCap = 'round';
      context.strokeStyle = 'black';
      context.lineWidth = 2;
      contextRef.current = context;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  const startDrawing = (e) => {
    e.preventDefault();
    const { offsetX, offsetY } = getCoordinates(e);

    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
    setHasSignature(true);
  };

  const draw = (e) => {
    e.preventDefault();
    if (!isDrawing) return;

    const { offsetX, offsetY } = getCoordinates(e);
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };

  const stopDrawing = () => {
    contextRef.current.closePath();
    setIsDrawing(false);
  };

  const getCoordinates = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (e.type.includes('touch')) {
      const touch = e.touches[0];
      return {
        offsetX: touch.clientX - rect.left,
        offsetY: touch.clientY - rect.top,
      };
    }
    return {
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
    };
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    setHasSignature(false);
    setBase64Image('');
  };

  const showMessage = (text, type) => {
    setMessage({ text, type, show: true });
    setTimeout(() => setMessage({ text: '', type: '', show: false }), 3000);
  };

  const saveSignature = () => {
    if (!hasSignature) {
      showMessage('Please draw a signature first', 'error');
      return;
    }

    // Create a Base64 image from the canvas
    const signatureImage = canvasRef.current.toDataURL('image/png');
    // Set the Base64 image to state
    setBase64Image(signatureImage);

    showMessage('Signature saved successfully!', 'success');
  };

  return (
    <div className="container-signature">
      <div className={`sing ${base64Image ? 'invisible' : ''}`}>
        <canvas
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
          onTouchStart={startDrawing}
          onTouchMove={draw}
          onTouchEnd={stopDrawing}
        />
      </div>
      <div className="buttons-action">
        <button className="clear-btn" onClick={clearCanvas}>
          Clear
        </button>
        <button className="save-btn" onClick={saveSignature}>
          Save Signature
        </button>
      </div>
      <div className={`message ${message.type} ${message.show ? 'show' : ''}`}>
        {message.text}
      </div>
    </div>
  );
};

Signature.propTypes = {
  base64Image: PropTypes.string,
  setBase64Image: PropTypes.func,
};

export default Signature;
