import React from 'react';
import MainLayout from '../Layout/MainLayout';

const EventDetails = () => {
  return (
    <>
      <MainLayout>
        <div className="event-details">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="event-card-img">
                  <div className="card bg-dark text-white">
                    <img
                      src="../../images/event-details.jpg"
                      className="card-img"
                      alt="..."
                    />
                    <div className="card-img-overlay">
                      <h5 className="card-title">Advanced Yoga Workshop</h5>
                      <p className="card-text">
                        Master the art of mindfulness and advanced poses with
                        expert guidance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <h3>Event</h3>
                    <div className="event-dd">
                      <div className="event-date-sel">
                        <img src="../images/date.svg" alt="" />
                        <div className="event-list-dd">
                          <p>Date</p>
                          <h5>March 15, 2025</h5>
                        </div>
                      </div>
                      <div className="event-date-sel">
                        <img src="../images/clock.svg" alt="" />
                        <div className="event-list-dd">
                          <p>Time</p>
                          <h5>9:00 AM - 11:00 AM</h5>
                        </div>
                      </div>
                      <div className="event-date-sel">
                        <img src="../images/location.svg" alt="" />
                        <div className="event-list-dd">
                          <p>Location</p>
                          <h5>Wellness Center, Downtown</h5>
                        </div>
                      </div>
                      <div className="event-date-sel">
                        <img src="../images/skill.svg" alt="" />
                        <div className="event-list-dd">
                          <p>Skill Level</p>
                          <h5>Advanced</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h3>Description</h3>
                    <p>
                      Join us for an immersive advanced yoga workshop designed
                      to deepen your practice and enhance your understanding of
                      complex asanas. This session will focus on advanced poses,
                      breathing techniques, and mindfulness practices.
                    </p>
                    <h4 className="bring">What to Bring</h4>
                    <ul className="event-create">
                      <li>Yoga mat</li>
                      <li>Water bottle</li>
                      <li>Small towel</li>
                      <li>Comfortable clothing</li>
                    </ul>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h3>Instructor</h3>
                    <div className="user-id">
                      <img
                        src="../../images/clint.png"
                        alt="user-img"
                        className="img-fluid user-img-add"
                      />
                      <div className="user-name-list">
                        <h4 className="user-name-f">Sarah Johnson</h4>
                        <p className="user-tan-cent">
                          Certified Yoga Instructor
                        </p>
                        <p className="user-raging">
                          <img src="../../images/star.svg" alt="" />
                          <span>4.9(120+review)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h3 className="d-flex align-items-center justify-content-between ">
                      Book Now <span className="price-dollar">$500</span>
                    </h3>
                    <label htmlFor="name" className="form-label">
                      Number of Participants
                    </label>
                    <select name="" id="" className="form-select input-outline">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <div className="total-all-price">
                      <p className="sub-coins">
                        Subtotal
                        <span>$500</span>
                      </p>
                      <p className="total-coins">
                        Total <span>$500</span>
                      </p>
                    </div>
                    <button
                      type="submit"
                      className="theme-button py-2 w-100 text-center justify-content-center"
                    >
                      Book Event
                    </button>
                    <p className="doors">
                      <img src="../../images/users-three.svg" alt="" />
                      Doors open 15 minutes before start
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default EventDetails;
