import { useRef, useState } from 'react';
import { postApi } from '../utils/api';
import { rangeBookingApi, rangePoliciesAcceptanceApi } from '../utils/endpoint';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { setHours, setMinutes, format } from 'date-fns';
import { ThreeDots } from 'react-loader-spinner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLayout from '../Layout/MainLayout';
import ErrorBanner from '../Pages/ErrorBanner';
import { WaiverModal } from './WaiverModal';

const RangeBookingForm = () => {
  const { personalDetail } = useSelector((state) => state.signup);
  const { state } = useLocation();
  const selectedRange = state && state.range;
  const today = new Date().toISOString().split('T')[0].toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [skillLevel, setSkillLevel] = useState('Novice/Never Ever');
  const [firearm, setFirearm] = useState('Handgun');
  const [rangePolicy, setRangePolicy] = useState(false);
  const [notes, setNotes] = useState('');
  const [isBookLoading, setIsBookLoading] = useState(false);
  const [isBookingDone, setIsBookingDone] = useState(false);
  const access_token = Cookies.get('userToken');
  const [availableDays, setAvailableDays] = useState([]);
  const [minTime, setMinTime] = useState();
  const [maxTime, setMaxTime] = useState();
  const [isParticipantAdded, setIsParticipantAdded] = useState(false);
  const [participant, setParticipant] = useState({});
  const [member, setIsMember] = useState({});
  const [policy, setPolicy] = useState({});
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNo: '',
    gender: '',
  });
  const [showError, setShowError] = useState(false);
  const [isWaiverSign, setIsWaiverSign] = useState(false);
  const [isWaiverShow, setIsWaiverShow] = useState(false);
  const [waiverSignData, setWaiverSignData] = useState({});
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });

  const fetchIsWaiverSign = () => {
    const formdata = new FormData();
    formdata.append('policy_id', policy?.id);
    formdata.append('user_id', personalDetail?.id);
    postApi(rangePoliciesAcceptanceApi, formdata, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setWaiverSignData(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (policy?.id) {
      fetchIsWaiverSign();
    }
  }, [policy?.id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleBook = (e) => {
    e.preventDefault();
    handleCloseError();

    const { name, email, telephone, gender } = personalDetail || {};
    const objMember =
      Object.keys(member).length > 0
        ? member
        : {
            fullName: name,
            email: email,
            phoneNo: telephone,
            gender: gender,
          };

    const participantArray = [objMember];

    if (isParticipantAdded) {
      participantArray.push(formData);
    }

    // Error messages
    const ERROR_MESSAGES = {
      sameDetails: 'Participant details cannot be same as yours.',
      emailAssociated:
        'The email you provided is already associated with an account.',
      phoneAssociated:
        'The phone number you provided is already associated with an account.',
      rangePolicy: 'Please read and agree to Range Policies.',
    };

    // Validate participant details
    if (formData.email === email || formData.phoneNo === telephone) {
      let errorMessage = ERROR_MESSAGES.sameDetails;

      if (formData.email === email && formData.phoneNo !== telephone) {
        errorMessage = ERROR_MESSAGES.emailAssociated;
      }

      if (formData.phoneNo === telephone && formData.email !== email) {
        errorMessage = ERROR_MESSAGES.phoneAssociated;
      }

      setShowError(true);
      setShowErrorDetail({ isError: true, message: errorMessage });
      setIsBookLoading(false);
      return;
    }

    // Check range policy
    if (!rangePolicy) {
      setShowError(true);
      setShowErrorDetail({
        isError: true,
        message: ERROR_MESSAGES.rangePolicy,
      });
      setIsBookLoading(false);
      return;
    }

    // Check if waiver is signed or policy ID exists
    if (!isWaiverSign && waiverSignData?.is_signed === false && policy?.id) {
      setIsWaiverShow(true);
      setIsBookLoading(false);
      return;
    }

    // Proceed with booking
    setIsBookLoading(true);
    const formdata = new FormData();
    formdata.append('range_id', selectedRange?.id);
    formdata.append('date', format(new Date(date), 'dd-MM-yyyy'));
    formdata.append('time', format(new Date(time), 'h:mm aa'));
    formdata.append('no_of_participants', participantArray.length);
    formdata.append('participants', JSON.stringify(participantArray));
    formdata.append('firearm', firearm);
    formdata.append('skill_level', skillLevel);
    formdata.append('notes', notes);

    postApi(rangeBookingApi, formdata, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setShowError(true);
          setIsBookingDone(true);
          setShowErrorDetail({
            isError: false,
            message:
              'Your reservation request is submitted successfully. Our range rep will contact you soon. Thank you!',
          });
          setTimeout(() => {
            handleClose();
            handleCloseError();
          }, 5000);
        }
      })
      .catch((err) => {
        setShowError(true);
        setShowErrorDetail({
          isError: true,
          message:
            err?.response?.data.message ||
            'An error occurred. Please try again.',
        });
      })
      .finally(() => {
        setIsBookLoading(false);
      });
  };

  const handleClose = () => {
    setDate('');
    // clearTimeout(timeoutId);
    setTime('');
    setNotes('');
    setIsBookingDone(false);
    setFirearm('Pistol Red-Dot');
    setSkillLevel('Novice/Never Ever');
    setRangePolicy(false);
    setFormData({
      fullName: '',
      email: '',
      phoneNo: '',
      gender: '',
    });
    navigate('/find-a-range');
  };

  const deleteParticipant = (e) => {
    e.preventDefault();
    setFormData({
      fullName: '',
      email: '',
      phoneNo: '',
      gender: '',
    });
    setIsParticipantAdded(false);
    setParticipant({});
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (
      formData.fullName &&
      formData.email &&
      formData.phoneNo &&
      formData.gender
    ) {
      setIsParticipantAdded(true);
      setParticipant(formData);
      const modalClose = document.getElementsByClassName(
        'btn-participant-close'
      );
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
    }
  };

  useEffect(() => {
    setAvailableDays([]);
    if (Object.keys(selectedRange).length > 0) {
      // Filter out closed days
      const closeDays = selectedRange?.range_hours.filter(
        (day) => day.closed === 1
      );
      if (closeDays.length > 0) {
        let excludelistmap = closeDays.map((item) => {
          let excludelist = generateExcludedDatesForDayOfWeek(Number(item.day));
          return excludelist;
        });
        const allExcludedDates = excludelistmap.flat();
        setAvailableDays(allExcludedDates);
      }
    }
  }, [selectedRange]);

  const generateExcludedDatesForDayOfWeek = (dayIndex) => {
    const excludedDates = [];
    // Loop through each month of the year
    for (let month = 0; month < 12; month++) {
      // Calculate the start and end dates of the current month
      const startDate = new Date(new Date().getFullYear(), month, 1);
      const endDate = new Date(new Date().getFullYear(), month + 1, 0);
      for (
        let currentDate = new Date(startDate);
        currentDate <= endDate;
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        const adjustedDayIndex = (currentDate.getDay() + 6) % 7;
        // Check if the current day matches the specified dayIndex (0 for Monday, 1 for Tuesday, ..., 6 for Sunday)
        if (adjustedDayIndex === dayIndex) {
          excludedDates.push(new Date(currentDate));
        }
      }
    }

    return excludedDates;
  };

  const handleDate = (date) => {
    const currentDate = new Date(
      new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
      })
    );
    const selectedDay = (date.getDay() + 6) % 7;
    const availableHoursForDay = selectedRange?.range_hours.find(
      (range) => Number(range.day) === selectedDay
    );

    if (availableHoursForDay) {
      let minDateTime = new Date();
      let maxDateTime = new Date();

      // Check if start_time and end_time are defined
      const startTime = availableHoursForDay.start_time;
      const endTime = availableHoursForDay.end_time;

      if (startTime && endTime) {
        const startTimeArray = startTime
          .split(':')
          .map((str) => parseInt(str, 10));
        const currentHours = currentDate.getHours();
        const currentMinutes = currentDate.getMinutes();

        if (
          date.getDate() === currentDate.getDate() &&
          date.getMonth() === currentDate.getMonth() &&
          date.getFullYear() === currentDate.getFullYear()
        ) {
          // If the selected date is the current day, set minimum time to the current time
          if (
            currentHours > startTimeArray[0] ||
            (currentHours === startTimeArray[0] &&
              currentMinutes >= startTimeArray[1])
          ) {
            minDateTime = currentDate;
          } else {
            minDateTime = new Date(
              setHours(
                setMinutes(
                  new Date(),
                  startTimeArray[1] // Use the parsed minutes
                ),
                startTimeArray[0] // Use the parsed hours
              )
            );
          }
        } else {
          minDateTime = new Date(
            setHours(
              setMinutes(
                new Date(),
                startTimeArray[1] // Use the parsed minutes
              ),
              startTimeArray[0] // Use the parsed hours
            )
          );
        }

        // Set maximum time
        maxDateTime = new Date(
          setHours(
            setMinutes(new Date(), endTime.split(':')[1]),
            parseInt(endTime.split(':')[0], 10)
          )
        );

        setMinTime(minDateTime);
        setMaxTime(maxDateTime);
      } else {
        console.error(
          'Start time or end time is not defined for the selected day.'
        );
      }
    } else {
      console.error('No available hours found for the selected day.');
    }

    setDate(date);
  };
  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsBookLoading(true);
      // Loop through each form element and remove error className
      Array.from(form.elements).forEach((element) => {
        element.classList.remove('error-input-border-red');
      });
      await handleBook(e);
    } else {
      // Loop through each form element and add error className if invalid
      Array.from(form.elements).forEach((element) => {
        if (!element.checkValidity()) {
          element.classList.add('error-input-border-red');
        } else {
          element.classList.remove('error-input-border-red');
        }
      });
      form.reportValidity();
    }
  };

  const handleCloseError = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  return (
    <>
      <MainLayout>
        {showError && (
          <ErrorBanner error={showErrorDetail} handleClose={handleCloseError} />
        )}

        <div className="account-login pt-5">
          <div className="modal-header firing-pin">
            <h2 className="modal-title  firing-heading" id="exampleModalLabel">
              {selectedRange?.name}
            </h2>
          </div>
          <form ref={formRef}>
            <div className="modal-body ">
              <div className="row grid-lay-out pb-0 m-b">
                <div className=" grid-tabs-list">
                  <div className="floating-group select-floating w-100 mb-f">
                    <DatePicker
                      selected={date}
                      required
                      onChange={(date) => handleDate(date)}
                      className="form-control"
                      placeholderText="mm-dd-yyyy"
                      excludeDates={availableDays}
                      dateFormat="MM-dd-yyyy"
                      disabled={isBookingDone}
                      minDate={today}
                    />
                    <label htmlFor="name" className="form-label label-text">
                      Choose Date
                    </label>
                  </div>
                  <div className="floating-group select-floating w-100 mb-f">
                    <DatePicker
                      className="form-control"
                      required
                      selected={time}
                      onChange={(date) => setTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="hh:mm"
                      disabled={isBookingDone || !date}
                      timeIntervals={60}
                      minTime={minTime}
                      maxTime={maxTime}
                    />
                    <label htmlFor="time" className="form-label label-text">
                      Choose Time
                    </label>
                  </div>
                  <div className="floating-group select-floating w-100 mb-f">
                    <select
                      id="inputState"
                      className="form-select st"
                      onChange={(e) => setSkillLevel(e.target.value)}
                      value={skillLevel}
                      required
                      disabled={isBookingDone}
                    >
                      <option value="Novice/Never Ever">
                        Novice/Never Ever
                      </option>
                      <option value="Beginner/In-training">
                        Beginner/In-training
                      </option>
                      <option value="Proficient/Intermediate">
                        Proficient/Intermediate
                      </option>
                      <option value="Advanced/Specialist">
                        Advanced/Specialist
                      </option>
                      <option value="Professional">Professional </option>
                    </select>
                    <label htmlFor="time" className="form-label label-text ">
                      Skill Level
                    </label>
                  </div>
                  <div className="floating-group select-floating w-100 mb-f">
                    <select
                      id="inputState"
                      className="form-select st"
                      onChange={(e) => setFirearm(e.target.value)}
                      value={firearm}
                      required
                      disabled={isBookingDone}
                    >
                      <option value="Handgun">Handgun</option>
                      <option value="Rifle">Rifle </option>
                      <option value="Shotgun">Shotgun </option>
                    </select>
                    <label htmlFor="time" className="form-label label-text ">
                      Firearm
                    </label>
                  </div>

                  <div className="d-flex pb-0 m-b w-full-row">
                    <div className="floating-group select-floating w-100 mb-f">
                      <textarea
                        className="form-control input-outline"
                        onChange={(e) => setNotes(e.target.value)}
                        value={notes}
                        disabled={isBookingDone}
                      ></textarea>
                      <label htmlFor="time" className="form-label label-text">
                        Note
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center w-full-row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) => setRangePolicy(e.target.checked)}
                        id="gridCheck"
                        checked={rangePolicy}
                        disabled={isBookingDone}
                      />
                      <label
                        className="form-check-label mt-1"
                        htmlFor="gridCheck"
                      >
                        I agree to the{' '}
                        <Link target="_blank" to="/range-policy">
                          Range Policies{' '}
                        </Link>
                        .
                      </label>
                    </div>
                  </div>
                  <div className="modal-footer border-0 w-full-row pe-0">
                    {!isBookingDone &&
                      !isWaiverShow &&
                      (waiverSignData?.is_signed === true ||
                        policy?.id ||
                        !policy?.id) && (
                        <button
                          type="button"
                          className={
                            'book-now-m book-now me-0' +
                            (isBookLoading ? ' disabled-btn' : '')
                          }
                          disabled={isBookLoading}
                          onClick={handleManualSubmit}
                        >
                          {isBookLoading ? (
                            <ThreeDots
                              height="21"
                              width="90"
                              radius="9"
                              color="#fff"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="800px"
                                height="800px"
                                viewBox="0 0 24 24"
                                id="Layer_1"
                                data-name="Layer 1"
                                fill="#fff"
                                className="cc"
                              >
                                <line
                                  className="cls-1"
                                  x1="12"
                                  y1="0.5"
                                  x2="12"
                                  y2="5.29"
                                />
                                <line
                                  className="cls-1"
                                  x1="12"
                                  y1="18.71"
                                  x2="12"
                                  y2="23.5"
                                />
                                <line
                                  className="cls-1"
                                  x1="23.5"
                                  y1="12"
                                  x2="18.71"
                                  y2="12"
                                />
                                <line
                                  className="cls-1"
                                  x1="5.29"
                                  y1="12"
                                  x2="0.5"
                                  y2="12"
                                />
                                <line
                                  className="cls-1"
                                  x1="20.13"
                                  y1="3.87"
                                  x2="16.74"
                                  y2="7.26"
                                />
                                <line
                                  className="cls-1"
                                  x1="7.26"
                                  y1="16.74"
                                  x2="3.87"
                                  y2="20.13"
                                />
                                <line
                                  className="cls-1"
                                  x1="20.13"
                                  y1="20.13"
                                  x2="16.74"
                                  y2="16.74"
                                />
                                <line
                                  className="cls-1"
                                  x1="7.26"
                                  y1="7.26"
                                  x2="3.87"
                                  y2="3.87"
                                />
                              </svg>
                              Book Now
                            </>
                          )}
                        </button>
                      )}

                    {isWaiverShow && waiverSignData?.is_signed === false && (
                      <button
                        type="button"
                        className={'book-now-m book-now me-0'}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Sign Waiver
                      </button>
                    )}
                  </div>
                </div>

                <div className="">
                  <div className="grid-list-new-ui">
                    <div className="card">
                      <button type="button" className="card-top-list">
                        Member
                      </button>
                      <div className="list-user-create">
                        <ul>
                          <li>
                            Name <span>{personalDetail?.name}</span>
                          </li>
                          <li>
                            Email <span>{personalDetail?.email}</span>
                          </li>
                          <li>
                            Mobile <span>{personalDetail?.telephone}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {!isParticipantAdded && (
                    <Link
                      to="#"
                      className="link-text d-block mb-3"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Add New Participant
                    </Link>
                  )}
                  {isParticipantAdded && (
                    <div className="grid-list-new-ui">
                      <div className="card">
                        <button type="button" className="card-top-list">
                          Participant
                        </button>
                        <div className="list-user-create">
                          <ul>
                            <li>
                              Name <span>{participant?.fullName}</span>
                            </li>
                            <li>
                              Email <span>{participant?.email}</span>
                            </li>
                            <li>
                              Mobile <span>{participant?.phoneNo}</span>
                            </li>
                          </ul>
                          <div className="only-btn d-flex default-bottom gap-3 justify-content-center ml-auto mr-auto mt-auto">
                            <button
                              className="button-black size-button size-border"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              type="button"
                              disabled={isBookingDone}
                            >
                              Edit
                            </button>
                            <button
                              className="button-black size-button size-border"
                              onClick={deleteParticipant}
                              disabled={isBookingDone}
                              type="button"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex gap-md-3 pb-0 m-b d-none">
                <div className="floating-group select-floating w-100 mb-f">
                  <input
                    id="inputState"
                    value={personalDetail?.name}
                    name="fullName"
                    disabled
                    type="text"
                    className={'form-control input-outline'}
                    required
                  />
                  <label htmlFor="time" className="form-label label-text ">
                    Full Name
                  </label>
                </div>
                <div className="floating-group select-floating w-100 mb-f">
                  <input
                    id="inputState"
                    value={personalDetail.email}
                    name="email"
                    disabled
                    type="email"
                    className={'form-control input-outline'}
                    required
                  />
                  <label htmlFor="time" className="form-label label-text ">
                    Email
                  </label>
                </div>
                <div className="floating-group select-floating w-100 mb-f">
                  <input
                    type="tel"
                    maxLength="10"
                    minLength="10"
                    onKeyPress={(e) => {
                      const regex = /^[0-9\b]+$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    id="inputState"
                    disabled
                    className={'form-control input-outline'}
                    required
                    value={personalDetail.telephone}
                    name="phoneNo"
                  />
                  <label htmlFor="time" className="form-label label-text ">
                    Mobile
                  </label>
                </div>
                <div className="floating-group select-floating w-100 mb-f">
                  <select
                    id="inputState"
                    className="form-select st"
                    value={personalDetail.gender}
                    name="gender"
                    required
                    disabled
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Non_Binary">Non Binary</option>
                  </select>
                  <label htmlFor="time" className="form-label label-text ">
                    Gender
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/*Participant Model */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body d-flex">
                <div className="container">
                  <form onSubmit={handleSave}>
                    <div className="row">
                      <h2 className="profile pro-changes">
                        Add New Participant
                      </h2>
                      <div className="col-lg-6">
                        <div className="floating-group floating-b">
                          <input
                            type="text"
                            required
                            value={formData.fullName}
                            onChange={handleInputChange}
                            name="fullName"
                            disabled={isBookingDone}
                            className="form-control input-outline"
                          />
                          <label htmlFor="fullName">Full Name*</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-group floating-b">
                          <input
                            required
                            value={formData.email}
                            onChange={handleInputChange}
                            name="email"
                            disabled={isBookingDone}
                            type="email"
                            className="form-control input-outline"
                          />
                          <label htmlFor="email">Email</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-group floating-b">
                          <input
                            type="tel"
                            maxLength="10"
                            minLength="10"
                            onKeyPress={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputState"
                            disabled={isBookingDone}
                            className={'form-control input-outline'}
                            required
                            value={formData.phoneNo}
                            onChange={handleInputChange}
                            name="phoneNo"
                          />
                          <label htmlFor="phoneNo">Mobile</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-group floating-b select-floating">
                          <select
                            id="gender"
                            className="form-select unite"
                            value={formData.gender}
                            onChange={handleInputChange}
                            name="gender"
                            required
                            disabled={isBookingDone}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non_Binary">Non Binary</option>
                          </select>
                          <label htmlFor="gender">Gender</label>
                        </div>
                      </div>
                      <div className="d-flex gap-2 mt-2">
                        <button
                          className="button-black size-button"
                          type="submit"
                        >
                          Save
                        </button>
                        <Link
                          id="cancel-id"
                          className="button-black size-button size-border cancel-add btn-participant-close"
                          data-bs-dismiss="modal"
                          to="#"
                          // onClick={handleCancelParticipant}
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Waiver */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <WaiverModal
              selectedRange={selectedRange}
              setShowErrorDetail={setShowErrorDetail}
              setShowError={setShowError}
              handleCloseError={handleCloseError}
              setIsMember={setIsMember}
              setIsWaiverShow={setIsWaiverShow}
              setIsWaiverSign={setIsWaiverSign}
              setPolicy={setPolicy}
            />
          </div>
        </div>
      </MainLayout>
    </>
  );
};

RangeBookingForm.propTypes = {
  selectedRange: PropTypes.object,
  setTimeoutId: PropTypes.func,
  timeoutId: PropTypes.number,
};

export default RangeBookingForm;
