import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { rangePoliciesApi } from '../utils/endpoint';
import Cookies from 'js-cookie';
import { getApi } from '../utils/api';
import Signature from './Signature';
import { useSelector } from 'react-redux';

export const WaiverModal = ({
  selectedRange,
  setShowErrorDetail,
  setShowError,
  handleCloseError,
  setIsMember,
  setIsWaiverSign,
  setIsWaiverShow,
  setPolicy,
}) => {
  const { personalDetail } = useSelector((state) => state.signup);
  const [rangePolicyList, setRangePolicyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkSign, setCheckSign] = useState(false);
  const access_token = Cookies.get('userToken');
  const [base64Image, setBase64Image] = useState('');
  const [isElectronicSignatureConsent, setIsElectronicSignatureConsent] =
    useState(false);
  const [isReceiveEmail, setIsReceiveEmail] = useState(false);

  useEffect(() => {
    if (selectedRange?.id) {
      fetchRangeWaiver();
    }
  }, [selectedRange?.id]);

  const fetchRangeWaiver = () => {
    getApi(
      rangePoliciesApi + `?range_id=${selectedRange?.id}&type=policy-waiver`,
      access_token
    )
      .then((res) => {
        if (res?.data?.success) {
          setRangePolicyList(res?.data.data);
          setPolicy(res?.data.data[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAgreeDocument = (e) => {
    e.preventDefault();
    handleCloseError();
    const errorMessages = {
      electronicSignature: 'Please agree to Electronic Signature Consent.',
      uploadSignature: 'Please upload your signature.',
    };

    if (!isElectronicSignatureConsent || base64Image === '') {
      const message = !isElectronicSignatureConsent
        ? errorMessages.electronicSignature
        : errorMessages.uploadSignature;

      setShowErrorDetail({
        isError: true,
        message,
      });
      setShowError(true);
      return;
    }

    const signWaiverForm = {
      fullName: personalDetail?.name,
      email: personalDetail?.email,
      phoneNo: personalDetail?.telephone,
      gender: personalDetail?.gender,
      electronic_signature: isElectronicSignatureConsent,
      signature: base64Image,
      receiveEmail: isReceiveEmail,
      waiverId: rangePolicyList[0].id,
    };

    setIsMember(signWaiverForm);
    setIsWaiverSign(true);
    setIsWaiverShow(false);
    const modalClose = document.getElementsByClassName('close-waiver');
    for (let i = 0; i < modalClose.length; i++) {
      modalClose[i].click();
    }
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title h-m" id="exampleModalLabel">
          Waiver for {selectedRange?.name}
        </h5>
        <button
          type="button"
          className="btn-close close-waiver"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      {!isLoading && (
        <div className="modal-body">
          <div className="d-flex gap-md-3 pb-0 m-b">
            {rangePolicyList?.map((item, index) => (
              <div key={index}>
                <div className="flex justify-between items-center">
                  <h6 className="text-lg font-bold text-gray-900">
                    {item?.title}
                  </h6>
                  <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex gap-md-3 pb-0 m-b">
            <div>
              <div className="d-none">
                <h3 className="page-h text-center">
                  Please select who will be participating...
                </h3>
                <div className="d-flex gap-2 justify-content-center">
                  <button type="button" className="theme-btn">
                    Adult
                  </button>
                  <button type="button" className="theme-btn">
                    Minor
                  </button>
                </div>

                <div className="agreement text-center mt-3">
                  <h3 className="page-h text-center">
                    This agreement is just for you
                  </h3>
                  <button type="button" className="theme-btn mt-0">
                    Continue
                  </button>
                </div>
              </div>
              <div className="mt-3 minor-parent-or-guardina-name">
                <div className="row">
                  <div className="form-group col-sm-12">
                    <h3 className="h-d">Member&apos;s Details</h3>
                  </div>
                  <div className="col-md-6">
                    <div className="floating-group floating-b select-floating">
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={personalDetail?.name}
                        name="name"
                        readOnly
                      />
                      <label htmlFor="name">Full Name*</label>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="floating-group floating-b">
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="firstName"
                        value={personalDetail?.first_name}
                      />
                      <label htmlFor="firstName">First Name*</label>
                    </div>
                  </div> */}
                  {/* <div className="col-md-4">
                    <div className="floating-group floating-b">
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="lastName"
                      />
                      <label htmlFor="lastName">Last Name</label>
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="floating-group floating-b select-floating">
                      <input
                        readOnly
                        type="tell"
                        className="form-control"
                        required
                        name="number"
                        value={personalDetail?.telephone}
                      />
                      <label htmlFor="number">Mobile</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="floating-group floating-b select-floating">
                      <input
                        type="date"
                        className="form-control"
                        required
                        name="dob"
                        value={personalDetail?.dob}
                        readOnly
                      />
                      <label htmlFor="dob">DOB</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="floating-group floating-b select-floating">
                      <input
                        type="email"
                        className="form-control"
                        required
                        name="email"
                        value={personalDetail?.email}
                        disabled
                      />
                      <label htmlFor="email">Email address</label>
                    </div>
                  </div>
                  <div className="form-gropu col-md-12 mt-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck-4"
                        checked={isReceiveEmail}
                        onChange={(e) => setIsReceiveEmail(e.target.checked)}
                      />
                      <label
                        className="form-check-label l-b"
                        htmlFor="gridCheck-4"
                      >
                        Check to receive information, news, and discounts by
                        e-mail.
                      </label>
                    </div>
                    <label htmlFor="name" className="l-b ps-4">
                      Your signed waiver will be sent to the email address
                      provided here and is available for download for three days
                      via URL attachment.
                    </label>
                  </div>
                  {checkSign && !base64Image && (
                    <Signature
                      base64Image={base64Image}
                      setBase64Image={setBase64Image}
                    />
                  )}
                  {/* Display the Base64 image */}
                  {base64Image && (
                    <>
                      <div className="d-flex justify-content-center">
                        <img
                          src={base64Image}
                          alt="Signature"
                          height={100}
                          style={{
                            marginTop: '20px',
                            border: '1px solid black',
                            width: '272px',
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          className="theme-btn"
                          onClick={() => setBase64Image('')}
                        >
                          Edit
                        </button>
                      </div>
                    </>
                  )}
                  {!checkSign && (
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="theme-btn"
                        onClick={() => setCheckSign(true)}
                      >
                        Check to Sign
                      </button>
                    </div>
                  )}
                </div>
                <div className="form-group col-sm-12 mt-4">
                  <h3 className="h-d">Electronic Signature Consent*</h3>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck-1"
                      checked={isElectronicSignatureConsent}
                      onChange={(e) =>
                        setIsElectronicSignatureConsent(e.target.checked)
                      }
                    />
                    <label
                      className="form-check-label l-b"
                      htmlFor="gridCheck-1"
                    >
                      By checking here, you are consenting to the use of your
                      electronic signature in lieu of an original signature on
                      paper. You have the right to request that you sign a paper
                      copy instead. By checking here, you are waiving that
                      right. After consent, you may, upon written request to us,
                      obtain a paper copy of an electronic record. No fee will
                      be charged for such copy and no special hardware or
                      software is required to view it. Your agreement to use an
                      electronic signature with us for any documents will
                      continue until such time as you notify us in writing that
                      you no longer wish to use an electronic signature. There
                      is no penalty for withdrawing your consent. You should
                      always make sure that we have a current email address in
                      order to contact you regarding any changes, if necessary.
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="theme-btn"
                    onClick={handleAgreeDocument}
                  >
                    Agree To This Document
                  </button>
                </div>
              </div>

              {/* Minor Code */}
              <div className="row d-none">
                <div className="minor">
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <h3 className="h-d">Minor&apos;s Name</h3>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="fullName"
                        />
                        <label htmlFor="fullName">Full Name*</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="middleName"
                        />
                        <label htmlFor="middleName">Middle Name*</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="lastName"
                        />
                        <label htmlFor="lastName">Last Name</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="tell"
                          className="form-control"
                          required
                          name="number"
                        />
                        <label htmlFor="floatingSeven">Mobile</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="date"
                          className="form-control"
                          required
                          name="dob"
                        />
                        <label htmlFor="floatingSeven">DOB</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="email"
                          className="form-control"
                          required
                          name="email"
                        />
                        <label htmlFor="email">Email address</label>
                      </div>
                    </div>
                    <div className="form-gropu col-md-12 mt-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="gridCheck-3"
                        />
                        <label
                          className="form-check-label l-b"
                          htmlFor="gridCheck-3"
                        >
                          Check to receive information, news, and discounts by
                          e-mail.
                        </label>
                      </div>
                      <label htmlFor="name" className="l-b ps-4">
                        Your signed waiver will be sent to the email address
                        provided here and is available for download for three
                        days via URL attachment.
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-3 minor-parent-or-guardina-name">
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <p className="count-parent">
                        Parent(s) or Court-Appointed Legal Guardian(s) must sign
                        for any participating minor (those under 18 years of
                        age) and agree that they and the minor are subject to
                        all the terms of this document, as set forth above.
                      </p>
                    </div>

                    <div className="form-group col-sm-12">
                      <h3 className="h-d">Participant&apos;s Name</h3>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="fullName"
                        />
                        <label htmlFor="fullName">Full Name*</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="middleName"
                        />
                        <label htmlFor="middleName">Middle Name*</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="lastName"
                        />
                        <label htmlFor="lastName">Last Name</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="tell"
                          className="form-control"
                          required
                          name="number"
                        />
                        <label htmlFor="floatingSeven">Mobile</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="date"
                          className="form-control"
                          required
                          name="dob"
                        />
                        <label htmlFor="floatingSeven">DOB</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-group floating-b">
                        <input
                          type="email"
                          className="form-control"
                          required
                          name="email"
                        />
                        <label htmlFor="email">Email address</label>
                      </div>
                    </div>
                    <div className="form-gropu col-md-12 mt-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="gridCheck-4"
                        />
                        <label
                          className="form-check-label l-b"
                          htmlFor="gridCheck-4"
                        >
                          Check to receive information, news, and discounts by
                          e-mail.
                        </label>
                      </div>
                      <label htmlFor="name" className="l-b ps-4">
                        Your signed waiver will be sent to the email address
                        provided here and is available for download for three
                        days via URL attachment.
                      </label>
                    </div>
                    <Signature />
                    <div className="d-flex justify-content-center">
                      <button type="button" className="theme-btn">
                        Check to Sign
                      </button>
                    </div>
                  </div>
                </div>

                <div className="form-group col-sm-12 mt-4">
                  <h3 className="h-d">Electronic Signature Consent*</h3>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck-1"
                    />
                    <label
                      className="form-check-label l-b"
                      htmlFor="gridCheck-1"
                    >
                      By checking here, you are consenting to the use of your
                      electronic signature in lieu of an original signature on
                      paper. You have the right to request that you sign a paper
                      copy instead. By checking here, you are waiving that
                      right. After consent, you may, upon written request to us,
                      obtain a paper copy of an electronic record. No fee will
                      be charged for such copy and no special hardware or
                      software is required to view it. Your agreement to use an
                      electronic signature with us for any documents will
                      continue until such time as you notify us in writing that
                      you no longer wish to use an electronic signature. There
                      is no penalty for withdrawing your consent. You should
                      always make sure that we have a current email address in
                      order to contact you regarding any changes, if necessary.
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <button type="button" className="theme-btn">
                    Agree To This Document
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

WaiverModal.propTypes = {
  selectedRange: PropTypes.object,
  setShowErrorDetail: PropTypes.func,
  setShowError: PropTypes.func,
  handleCloseError: PropTypes.func,
  setIsMember: PropTypes.func,
  setIsWaiverSign: PropTypes.func,
  setIsWaiverShow: PropTypes.func,
  setPolicy: PropTypes.func,
};
