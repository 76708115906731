import React, { useState } from 'react';
import * as Yup from 'yup';
import { ThreeDots } from 'react-loader-spinner';
import { getMembershipCardApi } from '../utils/endpoint';
import { postApi } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { setPersonalDetail } from '../redux/features/signup/signupSlice';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import SignUpLayout from '../Layout/SignUpLayout';

const AccountActivationForm = () => {
  const [membershipId, setMembershipId] = useState('');
  const [PINCode, setPINCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { membershipId, PINCode },
        { abortEarly: false }
      );
      setError('');
      setIsLoading(true);
      postApi(getMembershipCardApi, {
        membership_id: membershipId,
        pin_code: PINCode,
      })
        .then((res) => {
          setError('');
          setIsSuccess(true);
          setSuccessMsg('Account activated successfully');

          if (res.data?.data) {
            localStorage.setItem('joinEmail', res?.data?.data?.user?.email);
            localStorage.setItem(
              'isMembershipActivate',
              res?.data?.data?.membership_id
            );
            dispatch(setPersonalDetail(res?.data?.data?.user));
            Cookies.set('userToken', res?.data?.data?.user?.token, {
              expires: 7,
            });
            if (res.data?.data?.user?.subscriptions?.length === 0) {
              navigate('/plan-checkout');
            }
            if (res.data?.data?.user?.subscriptions?.length > 0) {
              setIsSuccess(true);
              setSuccessMsg(
                'Account already activated, redirecting to account !'
              );
              setTimeout(() => {
                setIsSuccess(false);
                setSuccessMsg('');
                navigate('/account');
              }, 4000);
            }
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsError(true);
          setErrorMsg(e.response?.data?.message);
          setTimeout(() => {
            setIsError(false);
            setErrorMsg('');
          }, 4000);
          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setError(validationErrors);
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <>
      <SignUpLayout>
        <div className="submit login-height">
          <h3>Welcome! Let&apos;s Get Your Account Activated.</h3>
          <div className="floating-group">
            <input
              type="text"
              value={membershipId}
              onChange={(e) => setMembershipId(e.target.value)}
              className={
                'form-control mt-4' +
                (error.membershipId && ' error-input-border-red ')
              }
              required
              onKeyPress={handleKeyPress}
              id="membershipId"
            />
            <label htmlFor="membershipId" className="label-text">
              Membership ID
            </label>
            {error && error.membershipId && (
              <p style={{ color: 'red' }}>{error.membershipId}</p>
            )}
          </div>
          <div className="floating-group">
            <input
              type="text"
              value={PINCode}
              onChange={(e) => setPINCode(e.target.value)}
              className={
                'form-control mt-4' +
                (error.PINCode && ' error-input-border-red ')
              }
              required
              onKeyPress={handleKeyPress}
              id="PINCode"
            />
            <label htmlFor="PINCode" className="label-text">
              PIN Code
            </label>
            {error && error.PINCode && (
              <p style={{ color: 'red' }}>{error.PINCode}</p>
            )}
          </div>
          {isError && (
            <h5 className="mt-2 bg-danger text-center text-white">
              {errorMsg}
            </h5>
          )}
          {isSuccess && (
            <h5 className="mt-2 bg-success text-center text-white">
              {successMsg}
            </h5>
          )}
          <div className="text-center mt-3">
            <a
              onClick={handleSubmit}
              className={
                'theme-button login-button' + (isLoading ? ' disabled-btn' : '')
              }
            >
              {isLoading ? (
                <ThreeDots
                  height="28"
                  width="128"
                  radius="1"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                'Continue'
              )}
            </a>
          </div>
        </div>
      </SignUpLayout>
    </>
  );
};

export default AccountActivationForm;

const validationSchema = Yup.object().shape({
  membershipId: Yup.string().required('Membership ID is required'),
  PINCode: Yup.string().required('PIN Code is required'),
});
