import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { getApi } from '../utils/api';
import { packages } from '../utils/endpoint';
import { useState } from 'react';

const Bill = (props) => {
  const [plan, setPlan] = useState({});
  const [planPackage, setPlanPackage] = useState({});
  let packagePlanType = localStorage.getItem('selectedPlanType');
  const isMembership = localStorage.getItem('isMembershipActivate');
  useEffect(() => {
    const fetchData = async () => {
      getApi(packages)
        .then((res) => {
          const responseData = res?.data?.data;
          let getprice;
          if (isMembership) {
            responseData?.map((item) => {
              const validPrePaidPackages = item.packages.filter(
                (package_data) =>
                  package_data.package_prices?.some(
                    (price_record) => price_record.is_prepaid
                  )
              );
              if (validPrePaidPackages.length > 0) {
                const prePaidPackage = validPrePaidPackages[0];
                setPlanPackage(prePaidPackage);
                getprice = prePaidPackage?.package_prices?.filter(
                  (resData) => resData.is_prepaid === true
                );
                const selectedMembership = getprice[0];
                selectedMembership.name = prePaidPackage?.name;
                selectedMembership.packageName = item.name;
                localStorage.setItem(
                  'selectedMembershipPlan',
                  JSON.stringify(selectedMembership)
                );

                let dataPrePaid = {
                  packageName: item.name,
                  name: prePaidPackage?.name,
                  price: getprice[0]?.price,
                  id: prePaidPackage?.id,
                  prepaid: getprice[0]?.is_prepaid,
                  activationFee: getprice[0]?.activation_fees,
                };
                setPlan(dataPrePaid);
              }
            });
          } else {
            let planData = responseData[0];
            setPlanPackage(planData);
            let filterPackage = packagePlanType ? packagePlanType : 'Monthly';
            getprice = planData?.packages[0]?.package_prices?.filter(
              (resData) => resData.price_frequency === filterPackage
            );
            if (responseData[0]) {
              let data = {
                name: planData?.name,
                price: getprice[0]?.price,
                id: planData?.id,
              };
              setPlan(data);
            }
          }
        })
        .catch((er) => {
          console.log(er);
        });
    };
    fetchData();
  }, []);

  const handlePlanTerm = () => {
    props?.setPlanInfo?.handlePlanSelection(!props?.setPlanInfo?.isPlan);
    if (!props.Yearly) {
      let selectedOption = !props?.setPlanInfo?.isPlan ? 'Yearly' : 'Monthly';
      let getprice = planPackage?.packages[0]?.package_prices?.filter(
        (resData) => resData.price_frequency === selectedOption
      );
      let data = {
        name: planPackage?.name,
        price: getprice[0]?.price,
        id: planPackage?.id,
      };
      setPlan(data);
    }
  };

  return (
    <>
      {plan && (
        <div className="">
          <h3 className="order-heading">{props.Heading}</h3>
          <div className="order-summary">
            <div className="cover-yearly">
              <h4>{props.Yearly ? props.Yearly : plan?.packageName}</h4>

              {props?.isTab &&
                props.PlanType !== '/ One-time' &&
                !plan?.prepaid && (
                  <div className="plan-select">
                    <p>
                      {props.Dollar ? props.Dollar : '$' + plan?.price}{' '}
                      {props.PlanType}
                    </p>
                    <div className="form-check form-switch">
                      <label
                        className="form-check-label m"
                        htmlFor="monthly"
                        style={{ textAlign: 'left' }}
                      >
                        Monthly
                      </label>
                      <input
                        className={`form-check-input ${
                          !props?.setPlanInfo?.isPlan ? 'month' : 'yearly'
                        }`}
                        type="checkbox"
                        id={!props?.setPlanInfo?.isPlan ? 'yearly' : 'monthly'}
                        onChange={handlePlanTerm}
                        checked={props?.setPlanInfo?.isPlan}
                      />
                      <label className="form-check-label" htmlFor="yearly">
                        Yearly
                      </label>
                    </div>
                  </div>
                )}
            </div>
            <div className="items-plan items-border-left-remove">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <p className="plan">
                        {plan?.prepaid ? plan?.name : props.PlanUser}
                      </p>
                    </td>
                    <td>
                      {plan?.prepaid ? (
                        <p className="price">
                          ( - {props.Price ? props.Price : '$' + plan?.price} )
                        </p>
                      ) : (
                        <p className="price">
                          {props.Price ? props.Price : '$' + plan?.price}
                        </p>
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                <td>
                  <p className="plan">{props.Add}</p>
                </td>
                <td>
                  <p className="price1">{props.AddPrice}</p>
                </td>
              </tr> */}
                  <tr>
                    <td>
                      <p className="plan">
                        {plan?.prepaid ? 'Activation Fee ' : props.Time}
                      </p>
                    </td>
                    <td>
                      <p className="price1">
                        {plan?.prepaid
                          ? '$' + plan?.activationFee
                          : props.TimePrice}
                      </p>
                    </td>
                  </tr>
                  {/* {props.isAdditional && (
                <tr>
                  <td>
                    <p className="plan">{props.Additional}</p>
                  </td>
                  <td>
                    <p className="price1">{props.AdditionalPrice}</p>
                  </td>
                </tr>
              )} */}

                  <tr>
                    <td>
                      <p className="plan">{props.Tax}</p>
                    </td>
                    <td>
                      <p className="price1">{props.TaxPrice}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table">
                <tbody>
                  <tr className="last-total">
                    <td>
                      <p className="total">{props.Total}</p>
                    </td>
                    <td>
                      <p className="all-total">
                        {props.TotalPrice
                          ? props.TotalPrice
                          : plan?.prepaid
                            ? '$' + plan?.activationFee
                            : '$' + parseFloat(plan?.price).toFixed(2)}
                      </p>
                      {/* <p className="all-total">
                        {props.TotalPrice
                          ? props.TotalPrice
                          : '$' + (parseFloat(plan?.price) + 20).toFixed(2)}
                      </p> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Bill.propTypes = {
  props: PropTypes.object,
  Heading: PropTypes.string,
  Yearly: PropTypes.string,
  Dollar: PropTypes.string,
  PlanUser: PropTypes.string,
  PlanType: PropTypes.string,
  Price: PropTypes.string,
  Add: PropTypes.string,
  AddPrice: PropTypes.string,
  Time: PropTypes.string,
  TimePrice: PropTypes.string,
  Additional: PropTypes.string,
  AdditionalPrice: PropTypes.string,
  Tax: PropTypes.string,
  TaxPrice: PropTypes.string,
  Total: PropTypes.string,
  TotalPrice: PropTypes.string,
  isAdditional: PropTypes.bool,
  setPlanInfo: PropTypes.object,
  isTab: PropTypes.bool,
};
export default Bill;
